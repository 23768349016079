import { 
    ShowButton,
    Datagrid,
    FilterButton, 
    List, 
    Pagination,
    TextField, 
    TextInput,
    TopToolbar,
    useListContext,
    usePermissions,
    useTranslate,
    useNotify,
    FunctionField,
    Button,
} from 'react-admin';
import { BACKEND_URL } from '../constants';

// Filters for the Response listing
const ResponseFilter = [
    <TextInput source="bot_language"/>,
    <TextInput source="content"/>,
    <TextInput source="response_code"/>,
]


// Paginator for the Response listing
const ResponsePaginator = (props: any) => <Pagination rowsPerPageOptions={[15, 30, 60, 90, 120, 150]} {...props}/>

const removeTags = (value: string) => {
    if (value) {
        return value.replace(/<[^>]+>/g, '');
    }
    return value;
}
const ResponseList = ({...props}) => {
    const translate = useTranslate()
    const {permissions} = usePermissions();
    const {data, isLoading} = useListContext();
    const notify = useNotify();
    
    const ListActions = ({permissions}: {permissions: any}) => (
        <TopToolbar>
            <Button 
                label='Pull Response from Staging'
                onClick={pullStagingResponse} 
                startIcon={<i className="fa fa-download" aria-hidden="true"></i>}
            />
            <FilterButton/>
        </TopToolbar>
    )

    // Pull staging response
    const pullStagingResponse = () => {
        const url = `${BACKEND_URL}/bot-response-pull`;
        fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${sessionStorage.getItem('token')}`,
            },
        }).then((response) => {
            if (response.status === 200) {
                notify('Successfully pulled staging response')
            } else {
                notify('Failed to pull staging response')
            }
        })
    }

    return (
        <List actions={<ListActions permissions={permissions}/>} filters={ResponseFilter} title={translate('resources.response.form.list')} exporter={false} pagination={<ResponsePaginator/>} perPage={15}>
            <Datagrid>
                <TextField label="id" source="id"/>
                <TextField label={translate('resources.response.fields.response_code')} source="response_code"/>
                <FunctionField label={translate('resources.response.fields.content')} source="content" render={(item: any) => {
                    return removeTags(item.content)
                }
                }/>
                <TextField label={translate('resources.response.fields.bot_language')} source="bot_language"/>
                <ShowButton />
            </Datagrid>
        </List>
    )
}

export default ResponseList;
