import {
    useState,
    useContext,
    useEffect
} from 'react'
import {
    InputBase,
    OutlinedInput,
    IconButton,
    Stack,
    Typography,
    Button, 
    Box
} from '@mui/material';
import axios from 'axios';
import AddReactionOutlinedIcon from '@mui/icons-material/AddReactionOutlined';
import PostAddOutlinedIcon from '@mui/icons-material/PostAddOutlined';
import SendSharpIcon from '@mui/icons-material/SendSharp';
import ConversationContext from '../context/ConversationContext';
//import axios from 'axios';
import { BACKEND_URL } from '../constants';
import FormData from "form-data";

const ConversationInput = () => {
    const [text, setText] = useState<null | String>('');
    const identity = sessionStorage.getItem('identity')
    const agent_id = sessionStorage.getItem('agent_id')

    const { onIntercept, selectedAgentId, targetId, inputDisabled, wsRef, selectedSenderId, selectedRoomId, selectedBotChannel, agentId, socketioConnection, userConnection, setUserConnection }: any = useContext(ConversationContext)
    const [selectedFile, setSelectedFile] = useState<File | null>(null);
    const [isFilePicked, setIsFilePicked] = useState(false);

    const sendAttachment = (event: any, selectedFile: any, selectedSenderId: any) => {
        const formData = new FormData();
        formData.append("upload_file", selectedFile);
        formData.append("sender_id",selectedSenderId);
        formData.append("room_id",selectedRoomId);
        formData.append("bot_channel",selectedBotChannel);
        formData.append("action",'live_agent');
        const token = sessionStorage.getItem('token')
        // if (selectedFile && selectedSenderId) {
        //     fetch(`${BACKEND_URL}/post-attachment`, {
        //         method: 'POST',
        //         body: selectedFile
        //       })
        //     .then(response => response.text())
        //     .then(result => console.log(result))
        //     .catch(error => console.log('error', error));       
        // }
        // ws_send.dispatch(wsSendMessage(JSON.stringify({ "action": "message", "source": "WEB", "target": "whatsapp", "sourceId": `${identity}`, "targetId": `${selectedSenderId}`, "type": "text", "content": { "message": `${inputMessage}`, "sender": "live_agent" } })))

        if (token && selectedFile && selectedSenderId) {


            // axios.post(
            //     `${BACKEND_URL}/post-attachment`,
            //     formData,
            //     {headers: { 'Content-Type': 'multipart/form-data' }}
            // )
            //     .then(res => console.log(res))
            //     .catch(err => console.warn(err));
            axios
                .post(`${BACKEND_URL}/post-attachment`,
                        formData,
                        {headers: { 'Content-Type': 'multipart/form-data' }}
                )
                .then(res => console.log(res))
                .catch(err => console.warn(err));
        }
        setIsFilePicked(false);
        setSelectedFile(null)
    }

    const sendInputMessage = (event: any) => {
        if (text !== "") {
            wsRef.current.send(JSON.stringify({action: 'message', target_id: selectedSenderId, source_id: identity, text: text, room_id: selectedRoomId, bot_channel: selectedBotChannel}))
            setText("")
        } else {
            setText("")
        }
    }

    const changeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSelectedFile(event.target.files ? event.target.files[0] : null);
        setIsFilePicked(true);
    };


    useEffect(() => {
        if (socketioConnection === true) return; 

        if (selectedBotChannel !== 'webchat') return;
        
        setUserConnection(false);
    }, [socketioConnection, selectedSenderId]);

    return (
        <div style={{ height: 'fit-content', display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
            {/* <FormControl sx={{ height: '20%', width: '80%' }}>
                <OutlinedInput multiline placeholder="Type something..." />
            </FormControl> */}

            <>
            {   isFilePicked === true &&
                    <Stack sx={{ m: 2 }} spacing={1} direction="row" alignItems="center" justifyContent="flex-start">
                        <Typography variant="caption" sx={{color: 'grey[200]'}}>{selectedFile && selectedFile.name?selectedFile.name:null}</Typography>
                        <Button sx={{color: 'red'}} variant="text" onClick={(e)=>{setSelectedFile(null); setIsFilePicked(false)}}>x</Button>
                    </Stack>
            }
            {   userConnection === true && isFilePicked === false &&
                <Box alignItems='center' sx={{ m:1 }}>
                    <InputBase 
                    disabled={(selectedAgentId !== agent_id) || onIntercept === false} 
                    inputProps={{ autoFocus: true }} 
                    value={text}
                    minRows={1}
                    onChange={e => setText(e.target.value)} 
                    sx={{ height: '15%', width: '80%', overflow: 'auto' }} 
                    placeholder="Type something..." 
                    onKeyPress={(event) => {
                        if (event.key === 'Enter') {
                            sendInputMessage(event); 
                            sendAttachment(event, selectedFile, selectedSenderId)
                        }
                    }}
                    />
                </Box>
            }
            {   userConnection === false &&
                <Box alignItems='center' sx={{ m:1 }}>
                    <Typography variant="body1" sx={{color: 'red'}}>User has disconnected</Typography>
                </Box>
            }
            <input accept="image/*" name="pic" id="fileUpload" style={{ visibility: "hidden", overflow: "hidden" }} type="file" multiple={false} onChange={changeHandler} />
            <Stack direction="row" spacing={1}>
                {/* <IconButton><AddReactionOutlinedIcon color="primary" fontSize='large' /></IconButton> */}
                <label htmlFor="fileUpload">
                {  isFilePicked === true &&
                    <IconButton disabled={(selectedAgentId !== agent_id) || onIntercept === false} color="primary" component="span">
                        <PostAddOutlinedIcon fontSize='large' />
                    </IconButton>
                }
                { isFilePicked === false &&
                    <IconButton disabled={(selectedAgentId !== agent_id) || onIntercept === false} component="span">
                        <PostAddOutlinedIcon fontSize='large' />
                    </IconButton>
                } 
                </label>
                <IconButton disabled={(selectedAgentId !== agent_id) || onIntercept === false} color="primary" onClick={(e) => { sendInputMessage(e); sendAttachment(e, selectedFile, selectedSenderId)}}><SendSharpIcon fontSize='large' /></IconButton>
            </Stack></>



        </div>
    )
}

export default ConversationInput
