import { Admin, Resource } from 'react-admin';
import polyglotI18nProvider from 'ra-i18n-polyglot';
import { englishMessages } from './i18n/en';
import {authProvider} from './auth-provider';
import { lightTheme } from './layout/themes';
import AppLoginPage from './login';
import { Layout } from './layout';
import User from './users';
import Role from './roles';
import Model from './bot-models';
import Response from './bot-responses'
import Prediction from './predictions'
import Conversation from './conversations'
import ConversationHistories from './conversation-histories'
import permissions from './access-control'
import ActivationSchedule from './activation-schedule';
import AuditTrail from './audit-trails'
import { AnalyticDashboard } from './analytic-dashboard'

import dataProvider from './data-provider';
import { DataProvider } from './context/ConversationContext';


const i18nProvider = polyglotI18nProvider(locale => {
  return englishMessages;
}, 'en');

const App = () => (
  <DataProvider>
  <Admin 
    dashboard={AnalyticDashboard}
    loginPage={AppLoginPage} 
    authProvider={authProvider} 
    dataProvider={dataProvider}
    i18nProvider={i18nProvider}
    theme={lightTheme}
    layout={Layout}>
    <Resource name="audit-trail" {...AuditTrail}/>
    <Resource name="users" {...User}/>
    <Resource name="roles" {...Role}/>
    <Resource name="activation-schedule" {...ActivationSchedule}/>
    <Resource name="bot-responses" {...Response}/>
    <Resource name="model" {...Model}/>
    <Resource name="conversations" {...Conversation}/>
    <Resource name="conversation-histories" {...ConversationHistories}/>
    <Resource name="prediction" {...Prediction}/>
    <Resource name="permission" {...permissions} />
  </Admin>
  </DataProvider>
)
export default App;
