import { useState, useEffect }  from 'react';
import Stack from '@mui/material/Stack';
import { Paper, FormControl, InputLabel, Select, MenuItem, Typography, Divider, Box } from '@mui/material';
import { BACKEND_URL } from '../../constants'
import { Chart as ChartJS, ArcElement, Tooltip } from 'chart.js/auto';
import dayjs, { Dayjs } from 'dayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

ChartJS.register(ArcElement, Tooltip);

export default function ConversationVolume() {
  const [period, setPeriod] = useState('daily');
  const [convCount, setConvCount] = useState(0);
  const [chartData, setChartData] = useState({
    labels: [],
    datasets: [
      {
        label: '',
        data: [],
        borderWidth: 1,
      },
    ],
  });

  const handleChangePeriod = (event: any) => {
    setPeriod(event.target.value);
  };
  const [selectedStartDate, setSelectedStartDate] = useState(dayjs().subtract(1, 'day'));
  const [selectedEndDate, setSelectedEndDate] = useState(dayjs());

  // Function to disable end dates earlier than the selected start date
  const shouldDisableDate = (day: any) => {
    // Convert selectedStartDate and day to Date objects
    const startDate = selectedStartDate ? new Date(selectedStartDate.toDate()) : null;
    const endDate = new Date(day);

    // If startDate is not set, don't disable any dates
    if (!startDate) {
        return false;
    }

    return endDate < startDate;
  };

  const fetchCountFromBackend = async () => {
    const apiUrl = `${BACKEND_URL}/conversation-volume?start_date=${selectedStartDate.format('YYYY-MM-DD')}&end_date=${selectedEndDate.format('YYYY-MM-DD')}`;
    fetch(apiUrl)
      .then((response) => response.json())
      .then((data) => {
        setConvCount(data)
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {    
    fetchCountFromBackend();
  }, [selectedStartDate, selectedEndDate]);

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      y: {
        beginAtZero: true,
      },
    },
  };

  return (
    <Paper sx={{backgroundColor: 'white', boarderRadius: '12px', height: '100%'}}>
      <Box sx={{ m: 1, display: 'flex', justifyContent: 'space-between'}}>
        <Stack alignItems='center' direction="column">
          <Typography sx={{ m: 1, fontFamily: 'Roboto', fontWeight: 'bold' }} variant="body2">Conversation Volumes</Typography>
          <Stack spacing={2} direction="row" justifyContent="space-between" sx={{width: '90%'}}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={['DatePicker', 'DatePicker']}>
                  <DatePicker
                      sx= {{ 
                        '& .MuiInputBase-input': {
                          backgroundColor: 'white',
                          padding: '10px'
                        },
                      }}
                      label="From"
                      value={selectedStartDate}
                      onChange={(date: any) => setSelectedStartDate(date)}
                      disableFuture
                  />
              </DemoContainer>
            </LocalizationProvider>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={['DatePicker', 'DatePicker']}>
                  <DatePicker
                      sx= {{ 
                        '& .MuiInputBase-input': {
                          backgroundColor: 'white',
                          padding: '10px'
                        },
                      }}
                      label="To"
                      value={selectedEndDate}
                      onChange={(date: any) => setSelectedEndDate(date)}
                      disableFuture
                      shouldDisableDate={(day: any) => shouldDisableDate(day) === true && day !== selectedStartDate}
                  />
              </DemoContainer>
            </LocalizationProvider>
          </Stack>
        </Stack>
      </Box>
      <br/>
      <br/>
      <br/>
      <br/>
      <Stack sx={{ m:1 }} divider={<Divider variant="middle" orientation="horizontal" flexItem />} alignItems="center" spacing={1} direction="column">
        <Stack spacing={5} alignItems="center" direction="column">
          <Stack spacing={1} alignItems="center" direction="row">
            <Typography variant="h6">Total Conversations</Typography>
          </Stack>
          <br/>
          <br/>
          <Typography variant="h1">{convCount}</Typography>
        </Stack>
      </Stack>
    </Paper>
  );
};
