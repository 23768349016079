import { useNavigate } from "react-router-dom"
import Button from '@mui/material/Button';
import CancelIcon from '@mui/icons-material/Cancel';
import { Edit, Toolbar, DeleteButton, SaveButton, useTranslate, TopToolbar, sanitizeListRestProps, useNotify, useRefresh, useRedirect, BooleanInput, AutocompleteInput, PasswordInput, SimpleForm, SelectInput, TextInput, required} from 'react-admin';
import {
	RichTextInput,
	RichTextInputToolbar,
	FormatButtons,
} from 'ra-input-rich-text';


const ListActions = (props: any) => {
    const {
        className,
        exporter,
        filters,
        maxResults,
        ...rest
    } = props

    const navigate = useNavigate()
    return (
        <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
        </TopToolbar>
    )
}

const BottomToolbar = ({ ...props }) => {
    const navigate = useNavigate()

    return (
        <Toolbar {...props} >
            <SaveButton disabled={props.invalid || props.pristine} />
            <Button variant="contained" onClick={() => navigate(`/bot-responses`)} startIcon={<CancelIcon />}>
                Cancel
            </Button>
            <DeleteButton />
        </Toolbar>
    )
}


const ResponseEdit = ({ ...props }) => {
    const translate = useTranslate()
    const notify = useNotify()
    const refresh = useRefresh();
    const redirect = useRedirect();
    const validateResponseName = [
        required(translate('resources.response.errors.response_code.required'))
    ]
    const validateLanguage = [required(translate('resources.response.errors.bot_language.required'))
    ]
    const validateUtterance = [
        required(translate('resources.response.errors.text.required'))
    ]

    const onEditSuccess = ({ data }: any) => {
        notify(`Bot Response "${data.target_name}" successfully updated`)
        redirect('show', props.basePath, data.id);
        refresh();
    };

    const transformRecord = (data: any) => {
        delete data['id']
        return {
            ...data
        }
    }

    const removeTags = (text: string) => {
        const pattern = /<(?!\/?(p|strong|em|u|s|code)\b)[^<]*?>/gi;
        let result = text.replace(pattern, '');
        return result;
    }

    return (
        <Edit title={translate('resources.response.form.edit')}
            transform={transformRecord}
            actions={<ListActions />}
            {...props} >
            <SimpleForm toolbar={<BottomToolbar />} redirect="list">
                <br />
                <TextInput variant="outlined" source="response_code"
                    validate={validateResponseName}
                    resettable={true}
                    label={translate('resources.response.fields.response_code')}
                    helperText={translate('resources.response.helperText.response_code')}
                    fullWidth />
                <SelectInput variant="outlined" source="bot_language"
                    validate={validateLanguage}
                    resettable={true}
                    label={translate('resources.response.fields.bot_language')}
                    helperText={translate('resources.response.helperText.bot_language')}
                    fullWidth
                    choices={[
                        { id: "en", name: "English" },
                        { id: 'bm', name: "Bahasa Malaysia" },
                    ]} />
                <RichTextInput
                    source="content"
                    label={translate('resources.response.fields.content')}
                    helperText={translate('resources.response.helperText.content')}
                    fullWidth 
                    toolbar={
                        <RichTextInputToolbar>
                            <FormatButtons/>
                        </RichTextInputToolbar>
                    }
                    parse={removeTags}
                />
            </SimpleForm>
        </Edit>
    )
}

export default ResponseEdit