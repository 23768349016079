import { 
    CreateButton,
    ShowButton,
    Datagrid,
    FilterButton, 
    List, 
    Pagination,
    TextField, 
    TextInput,
    TopToolbar,
    useListContext,
    usePermissions,
    useTranslate,
    Button,
    useNotify,
} from 'react-admin';
import { useState, cloneElement, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import RocketIcon from '@mui/icons-material/Rocket';
import TipsAndUpdatesIcon from '@mui/icons-material/TipsAndUpdates';
import { BACKEND_URL } from '../constants';
import { clear } from 'console';
import { pull, set } from 'lodash';

const ModelPaginator = (props: any) => <Pagination rowsPerPageOptions={[15, 30, 60, 90, 120, 150]} {...props}/>

const ModelList = ({...props}) => {
    const translate = useTranslate()
    const {permissions} = usePermissions();
    const listContext = useListContext();
    const {
        data,
        isLoading,
    } = listContext;
    const notify = useNotify();
    const [activationInProgress, setActivationInProgress] = useState(false)


    const checkIfActivationInProgress = () => {
        const url = `${BACKEND_URL}/train/activation_status`
        fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        })
        .then(response => response.json())
        .then(data => {
            if (data.is_activating){
                setActivationInProgress(true)
            } else {
                setActivationInProgress(false)
            }
        })
        .catch((error) => {
            console.error('Error:', error);
        });
    }

    useEffect(() => {
        // check once load
        checkIfActivationInProgress()

        // check every 10 seconds
        const interval = setInterval(() => {
            checkIfActivationInProgress()
        }, 5000);
        return () => clearInterval(interval);
    }, []);
    
    const pullModels = () => {
        const url = `${BACKEND_URL}/model/pull`
        const token = sessionStorage.getItem('token');
        fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            },
        })
        .then(response => response.json())
        .then(data => {
            if (data.message == 'started'){
                notify('Started pulling models from staging')
            } else if (data.message == 'in progress'){
                notify('Pulling models from staging')
            }
        })
        .catch((error) => {
            console.error('Error:', error);
        });
    }

    const activateLatest = () => {
        setActivationInProgress(true)
        notify('resources.model.actions.activate.started')
        const token = sessionStorage.getItem('token');
        const url = `${BACKEND_URL}/train/activate`
        fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            },
        })
        .then(response => response.json())
        .then(data => {
            notify('resources.model.actions.activate.success')
            console.log(data)
        })
        .catch((error) => {
            console.error('Error:', error);
        });
    }

    const ListActions = ({permissions}: {permissions: any}) => (
        <TopToolbar>
            <Button 
                label='Pull Models from Staging'
                onClick={pullModels} 
                startIcon={<RocketIcon/>}
            />
            <Button 
                label={activationInProgress ? "resources.model.actions.activate.in_progress" : "resources.model.actions.activate.title"}
                onClick={activateLatest} 
                disabled={activationInProgress}
                startIcon={<TipsAndUpdatesIcon/>}
            />
        </TopToolbar>
    )


    return (
        <List sort={{field: 'created_at', order: 'DESC'}}  actions={<ListActions permissions={permissions}/>} title={translate('resources.model.form.list')} exporter={false} pagination={<ModelPaginator/>} perPage={15} queryOptions={{ refetchInterval: 5000 }}>
            <Datagrid bulkActionButtons={false}>
                <TextField label={translate('resources.model.fields.name')} source="name"/>
                <TextField label={translate('resources.model.fields.date')} source="date"/>
                <TextField label={translate('resources.model.fields.state')} source="state"/>
            </Datagrid>
        </List>
    )
}

export default ModelList;
