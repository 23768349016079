import { Show, Toolbar, EditButton, DateTimeInput, useTranslate, SimpleForm, TextInput, usePermissions, } from 'react-admin';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const BottomToolbar = ({ ...props }) => {
    const navigate = useNavigate()
    return (
        <Toolbar {...props} >
            <EditButton record={props.data} />
            <Button variant="contained" onClick={() => navigate("/activation-schedule")} startIcon={<ArrowBackIcon />}>
                BACK
            </Button>
        </Toolbar>
    )
}

const ActivationScheduleShow = ({ ...props }) => {
    const translate = useTranslate()
    const { permissions } = usePermissions();
    return (
        <Show title={translate('resources.activation_schedule.form.show')}
            {...props} >
            <SimpleForm toolbar={<BottomToolbar permissions={permissions} />}>
                <br />
                <DateTimeInput source="time"
                    disabled
                    label={translate('resources.activation_schedule.fields.time')}
                    helperText={translate('resources.activation_schedule.helperText.time')}
                />
                <TextInput source="description"
                    disabled
                    label={translate('resources.activation_schedule.fields.description')}
                    helperText={translate('resources.activation_schedule.helperText.description')}
                    fullWidth />
            </SimpleForm>
        </Show>
    )
}

export default ActivationScheduleShow;