import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import PredictionShow from './prediction-show';
import PredictionEdit from './prediction-edit';
import PredictionList from './prediction-list';

export default {
    list: PredictionList,
    show: PredictionShow,
    edit: PredictionEdit,
    icon: QuestionAnswerIcon,
}
