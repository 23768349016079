import {
    Show,
    Toolbar,
    TopToolbar,
    sanitizeListRestProps,
    useTranslate,
    SimpleForm,
    TextInput,
    usePermissions
} from 'react-admin';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';
import {
	RichTextInput,
	RichTextInputToolbar,
	FormatButtons,
} from 'ra-input-rich-text';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';

/**
 * Customized bottom Form Toolbar
 * 
 * @param {} param0 
 */
const BottomToolbar = ({ ...props }) => {
    const navigate = useNavigate()
    return (
        <Toolbar {...props} >
            <Button variant="contained" onClick={() => navigate("/bot-responses")} startIcon={<ArrowBackIcon />}>
                BACK
            </Button>
        </Toolbar>
    )
}

const ListActions = (props: any) => {
    const {
        className,
        exporter,
        filters,
        maxResults,
        ...rest
    } = props

    const history = useNavigate()
    return (
        <div>
            <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
            </TopToolbar>
        </div>

    )
}


const ResponseShow = ({ ...props }) => {
    const translate = useTranslate()
    const { permissions } = usePermissions();
    return (
        <Show title={translate('resources.response.form.show')}
            {...props} >
            <SimpleForm toolbar={<BottomToolbar permissions={permissions} />}>
                <br />
                <TextInput source="response_code"
                    disabled
                    label={translate('resources.response.fields.response_code')}
                    fullWidth />
                <TextInput source="bot_language"
                    disabled
                    label={translate('resources.response.fields.bot_language')}
                    fullWidth />
                <RichTextInput
                    source="content"
                    label={translate('resources.response.fields.content')}
                    fullWidth 
                    toolbar={
                        <RichTextInputToolbar>
                            <FormatButtons />
                        </RichTextInputToolbar>
                    }
                    disabled={true}
                />
            </SimpleForm>
        </Show>
    )
}

export default ResponseShow;

// <TextInput source="content"
//     disabled
//     label={translate('resources.response.fields.content')}
//     helperText={translate('resources.response.helperText.content')}
//     fullWidth multiline 
// />

// const removeTags = (text: string) => {
//     console.log(`before: ${text}`)
//     text = text.replace(/<\/p><p>/g, '\n\n');

//     const pattern = /<(?!\/?(strong|em|u|s|code)\b)[^<]*?>/gi;
//     let result = text.replace(pattern, '');
//     console.log(`after: ${result}`)
//     return result;
// }