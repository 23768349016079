import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import ActivationScheduleCreate from './activation-schedule-create';
import ActivationScheduleList from './activation-schedule-list';
import ActivationScheduleShow from './activation-schedule-show';
import ActivationScheduleEdit from './activation-schedule-edit';

export default {
    create: ActivationScheduleCreate,
    list: ActivationScheduleList,
    show: ActivationScheduleShow,
    edit: ActivationScheduleEdit,
    icon: QuestionAnswerIcon,
}