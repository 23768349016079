import Chat from '@mui/icons-material/Chat';
import SecurityIcon from '@mui/icons-material/Security';
import Box from '@mui/material/Box';
import { useState } from 'react';
import HistoryIcon from '@mui/icons-material/History';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';


import {
    DashboardMenuItem,
    MenuItemLink,
    MenuProps,
    useSidebarState,
    useTranslate,
} from 'react-admin';

import responses from '../bot-responses';
import models from '../bot-models';
import roles from '../roles';
import auditTrail from '../audit-trails';
import users from '../users';
import SubMenu from './sub-menu';

type MenuName = 'menuCatalog' | 'menuSales' | 'menuCustomers' | 'menuTraining' | 'menuConversations';

const Menu = ({ dense = false }: MenuProps) => {
    const [state, setState] = useState({
        menuCatalog: true,
        menuSales: true,
        menuCustomers: true,
        menuTraining: true,
        menuConversations: true,
    });
    const translate = useTranslate();
    const [open] = useSidebarState();

    const handleToggle = (menu: MenuName) => {
        setState(state => ({ ...state, [menu]: !state[menu] }));
    };

    return (
        <Box
            sx={{
                width: open ? 200 : 50,
                marginTop: 1,
                marginBottom: 1,
                transition: theme =>
                    theme.transitions.create('width', {
                        easing: theme.transitions.easing.sharp,
                        duration: theme.transitions.duration.leavingScreen,
                    }),
            }}
        >
            <DashboardMenuItem />
            <SubMenu
                handleToggle={() => handleToggle('menuSales')}
                isOpen={state.menuSales}
                name="app.menu.security"
                icon={<SecurityIcon sx={{ color: '#212121' }} />}
                dense={dense}
            >
                <MenuItemLink
                    to="/users"
                    state={{ _scrollToTop: true }}
                    primaryText={translate(`resources.user.name`, {
                        smart_count: 2,
                    })}
                    leftIcon={<users.icon sx={{ color: '#1877F2' }}/>}
                    dense={dense}
                />
                <MenuItemLink
                    to="/roles"
                    state={{ _scrollToTop: true }}
                    primaryText={translate(`resources.role.name`, {
                        smart_count: 2,
                    })}
                    leftIcon={<roles.icon sx={{ color: '#1877F2' }}/>}
                    dense={dense}
                />
            </SubMenu>
            <SubMenu
                handleToggle={() => handleToggle('menuConversations')}
                isOpen={state.menuConversations}
                name="app.menu.conversation"
                icon={<SupportAgentIcon sx={{ color: '#212121' }}/>}
                dense={dense}
            >
                <MenuItemLink
                    to="/conversations"
                    state={{ _scrollToTop: true }}
                    primaryText={translate(`app.menu.externalConversation`, {
                        smart_count: 2,
                    })}
                    leftIcon={<Chat sx={{ color: '#25D366' }} />}
                    dense={dense}
                />
                <MenuItemLink
                    to="/conversation-histories"
                    state={{ _scrollToTop: true }}
                    primaryText={translate(`app.menu.conversationHistory`, {
                        smart_count: 2,
                    })}
                    leftIcon={<HistoryIcon sx={{ color: '#9e9e9e' }} />}
                    dense={dense}
                />
            </SubMenu>
            <MenuItemLink
                to="/bot-responses"
                state={{ _scrollToTop: true }}
                primaryText={translate(`resources.response.name`, {
                    smart_count: 2,
                })}
                leftIcon={<responses.icon />}
                dense={dense}
            />
            <MenuItemLink
                to="/prediction"
                state={{ _scrollToTop: true }}
                primaryText={translate(`resources.prediction.name`, {
                    smart_count: 2,
                })}
                leftIcon={<responses.icon />}
                dense={dense}
            />
            <MenuItemLink
                to="/activation-schedule"
                state={{ _scrollToTop: true }}
                primaryText={translate(`resources.activation_schedule.name`, {
                    smart_count: 2,
                })}
                leftIcon={<responses.icon />}
                dense={dense}
            />
            <MenuItemLink
                to="/model"
                state={{ _scrollToTop: true }}
                primaryText={translate(`resources.model.name`, {
                    smart_count: 2,
                })}
                leftIcon={<responses.icon />}
                dense={dense}
            />
            <MenuItemLink
                    to="/audit-trail"
                    state={{ _scrollToTop: true }}
                    primaryText={translate(`app.menu.audit_trail`, {
                        smart_count: 2,
                    })}
                    leftIcon={<auditTrail.icon sx={{ color: '#212121' }}/>}
                    dense={dense}
            />
        </Box>
    );
};

export default Menu;
