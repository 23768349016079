import { CreateButton, ShowButton, Datagrid, FilterButton, List, Pagination, TextField, TextInput, TopToolbar, useListContext, usePermissions, useTranslate, } from 'react-admin';

const ActivationScheduleFilter = [
    <TextInput source="time"/>,
    <TextInput source="description"/>
]

const ActivationSchedulePaginator = (props: any) => <Pagination rowsPerPageOptions={[15,30,60,90,120,150]} {...props}/>

const ListActions = ({permissions}: {permissions: any}) => (
    <TopToolbar>
        <FilterButton/>
        <CreateButton/>
    </TopToolbar>
)

const ActivationScheduleList = ({...props}) => {
    const translate = useTranslate()
    const {permissions} = usePermissions();
    const listContext = useListContext();
    const {
        data,
        filterValues,
        setFilters,
        displayedFilters,
        isLoading,
    } = listContext;
    return (
        <List actions={<ListActions permissions={permissions}/>} filters={ActivationScheduleFilter} title={translate('resources.activation_schedule.form.list')}  exporter={false} pagination={<ActivationSchedulePaginator/>}>
            <Datagrid>
                <TextField label={translate('resources.activation_schedule.fields.time')} source="time"/>
                <TextField label={translate('resources.activation_schedule.fields.description')} source="description"/>
                <ShowButton />
            </Datagrid>
        </List>
    )
}

export default ActivationScheduleList;
