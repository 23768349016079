import * as React from 'react';
import { AppBar, Logout, UserMenu, useTranslate, BooleanInput } from 'react-admin';
import { Link } from 'react-router-dom';
import {
    Box,
    MenuItem,
    ListItemIcon,
    Typography,
    useMediaQuery,
    Theme,
} from '@mui/material';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import { BACKEND_URL } from '../constants';
import Switch from '@mui/material/Switch';
import { styled } from '@mui/material/styles';
import LiveAgentRequest from './components/agent-request';



const GoOfflineSwitch = styled(Switch)(({ theme }) => ({
  width: 62,
  height: 34,
  padding: 7,
  '& .MuiSwitch-switchBase': {
    margin: 1,
    padding: 0,
    transform: 'translateX(6px)',
    '&.Mui-checked': {
      color: '#fff',
      transform: 'translateX(22px)',
      '& .MuiSwitch-thumb': {
        backgroundColor: '#4caf50',
        '&:before': {
          backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23ffffff"><path d="M12,0C5.373,0,0,5.373,0,12s5.373,12,12,12s12-5.373,12-12S18.627,0,12,0z M17.657,17.657 c-1.171,1.171-2.734,1.828-4.414,1.828s-3.243-0.656-4.414-1.828C7.171,15.486,6.514,12.757,8.171,10.829L7.758,10.416 C6.343,12.057,6.943,14.571,8.757,16.385c1.814,1.814,4.328,2.414,5.943,0.999l0.414,0.414C15.243,17.243,12.514,17.9,17.657,17.657z M12,2.857c4.771,0,8.629,3.858,8.629,8.629S16.771,20.114,12,20.114S3.371,16.256,3.371,11.486S7.229,2.857,12,2.857z"/></svg>')`,
        },
      },
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: '#6fbf73',
      },
    },
  },
  '& .MuiSwitch-thumb': {
    backgroundColor: '#BDBDBD',
    width: 32,
    height: 32,
  },
  '& .MuiSwitch-track': {
    opacity: 1,
    backgroundColor: '#CFCFCF',
    borderRadius: 20 / 2,
  },
}));


const UserAccountMenu = React.forwardRef((props, ref) => {
    const translate = useTranslate();
    return (
        <MenuItem component={Link} 
                  // @ts-ignore 
                  ref={ref} {...props} to="/user-account">
            <ListItemIcon>
                <AccountBoxIcon />
            </ListItemIcon>{translate('app.userAccount')}
        </MenuItem>
    )
});
const CustomUserMenu = () => (
    <UserMenu>
        <UserAccountMenu />
        <Logout />
    </UserMenu>
);

const CustomAppBar = (props: any) => {
    const isLargeEnough = useMediaQuery<Theme>(theme =>
        theme.breakpoints.up('sm')
    );
    const translate = useTranslate()
    const [isOnline, setIsOnline] = React.useState(true);
    const [status, setStatus] = React.useState(1);
    const agent_id = sessionStorage.getItem('agent_id')

    const handleSwitchChange = (event: any) => {
        setIsOnline(event.target.checked);
        setStatus(isOnline? 2:1)
    };

    React.useEffect(() => {
        if (status) {
            switchOnlineStatus();
        }
    }, [status]);

    const switchOnlineStatus = async () => {
        const payload = {
            id: agent_id,
            status: status
        };
        try {
          const response = await fetch(`${BACKEND_URL}/live-agent?status=${status}&id=${agent_id}`, {
            method: 'PUT',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(payload)
          });
            const data = await response.json();
            console.log(data)
          await new Promise(resolve => setTimeout(resolve, 1000)); 
        } catch (error) {
          console.error("Failed to save status:", error);
        }
    };

    React.useEffect(() => {
        const fetchOnlineStatus = async () => {
          try {
            const response = await fetch(`${BACKEND_URL}/live-agent/${agent_id}`);
            const data = await response.json();
            console.log(data)
            sessionStorage.setItem("university_id", data.university)
          } catch (error) {
            console.error("Failed to fetch status from backend:", error);
          }
        };
        fetchOnlineStatus();
    }, []);

    return (
        <AppBar
            {...props}
            color="secondary"
            elevation={1}
            userMenu={<CustomUserMenu />}
        >
            <Typography
                variant="h6"
                color="inherit"
                sx={{
                    flex: 1,
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                }}
                id="react-admin-title"
            />
            {isLargeEnough && <Box component="span" sx={{ flex: 1 }} />}
        </AppBar>

    );
};

export default CustomAppBar;

// {
//     agent_id && <GoOfflineSwitch checked={isOnline} onChange={handleSwitchChange} name="offline-switch"/>
// }
// {
//     agent_id && <LiveAgentRequest/>
// }