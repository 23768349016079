import { useState, useEffect }  from 'react';
import Badge from '@mui/material/Badge';
import Stack from '@mui/material/Stack';
import { Paper, FormControl, InputLabel, Select, MenuItem, Typography, Divider, Box } from '@mui/material';
import { BACKEND_URL } from '../../constants'
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import dayjs, { Dayjs } from 'dayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

ChartJS.register(ArcElement, Tooltip);

export default function PublicCompanyRenewal() {
  const [basis, setBasis] = useState('daily');
  const [companyCount, setCompanyCount] = useState(0);
  const [publicCount, setPublicCount] = useState(0);
  const [renewalCount, setRenewalCount] = useState(0);
  const [selectedStartDate, setSelectedStartDate] = useState(dayjs().subtract(1, 'day'));
  const [selectedEndDate, setSelectedEndDate] = useState(dayjs());

  const [chartData, setChartData] = useState({
    labels: ['Company Account Registration','Public Account Registration','E-license Renewal'],
    datasets: [
      {
        data: [
          companyCount, publicCount, renewalCount
        ],
        backgroundColor: [
          '#25D366',
          '#1877F2',
          '#2E7D32',
        ],
        borderColor: [
          'white',
          'white',
          'white',
        ],
        borderWidth: 2,
      },
    ],
  });

  // Function to disable end dates earlier than the selected start date
  const shouldDisableDate = (day: any) => {
    // Convert selectedStartDate and day to Date objects
    const startDate = selectedStartDate ? new Date(selectedStartDate.toDate()) : null;
    const endDate = new Date(day);

    // If startDate is not set, don't disable any dates
    if (!startDate) {
        return false;
    }

    return endDate < startDate;
  };

  const handleChangeBasis = (event: any) => {
    setBasis(event.target.value);
  };

  const options = {
    cutout: 70, // set the cutoutPercentage to 70 to create a custom inner radius
  };
  
  const fetchCountFromBackend = async () => {
    const apiUrl = `${BACKEND_URL}/get-public-company-renewal?start_date=${selectedStartDate.format('YYYY-MM-DD')}&end_date=${selectedEndDate.format('YYYY-MM-DD')}`;

    fetch(apiUrl)
      .then((response) => response.json())
      .then((data) => {
        setCompanyCount(data.companyCount)
        setPublicCount(data.publicCount)
        setRenewalCount(data.renewalCount)
        const newData = {
          labels: ['Company Account Registration','Public Account Registration','E-license Renewal'],
          datasets: [
            {
              data: [
                data.companyCount, data.publicCount, data.renewalCount
              ],
              backgroundColor: [
                '#243E36',
                '#7CA982',
                '#C2A83E',
              ],
              borderColor: [
                'white',
                'white',
                'white',
              ],
              borderWidth: 2,
            },
          ],
        };
        setChartData(newData);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {    
    fetchCountFromBackend();
  }, [selectedStartDate, selectedEndDate]);

  return (
    <Paper sx={{backgroundColor: 'white', boarderRadius: '12px', height: '100%'}}>
      <Box sx={{ m: 1, display: 'flex', justifyContent: 'space-between'}}>
        <Stack alignItems='center' direction="column">
          <Typography sx={{ m: 1, fontFamily: 'Roboto', fontWeight: 'bold' }} variant="body2">Registrations & Renewals</Typography>
          <Stack spacing={2} direction="row" justifyContent="space-between" sx={{width: '90%'}}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={['DatePicker', 'DatePicker']}>
                  <DatePicker
                      sx= {{ 
                        '& .MuiInputBase-input': {
                          backgroundColor: 'white',
                          padding: '10px'
                        },
                      }}
                      label="From"
                      value={selectedStartDate}
                      onChange={(date: any) => setSelectedStartDate(date)}
                      disableFuture
                  />
              </DemoContainer>
            </LocalizationProvider>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={['DatePicker', 'DatePicker']}>
                  <DatePicker
                      sx= {{ 
                        '& .MuiInputBase-input': {
                          backgroundColor: 'white',
                          padding: '10px'
                        },
                      }}
                      label="To"
                      value={selectedEndDate}
                      onChange={(date: any) => setSelectedEndDate(date)}
                      disableFuture
                      shouldDisableDate={(day: any) => shouldDisableDate(day) === true && day !== selectedStartDate}
                  />
              </DemoContainer>
            </LocalizationProvider>
          </Stack>
        </Stack>
      </Box>
      <br/>
      <br/>
      <Stack flexDirection="row" justifyContent="center">
        <Box width={250} height={250}>
          <Doughnut data={chartData} options={options}/>
        </Box>
      </Stack>
      <br/>
      <br/>
      <Stack sx={{ m:2 }} divider={<Divider variant="middle" orientation="horizontal" flexItem />} alignItems="flex-start" spacing={1} direction="column">
        <Stack spacing={30} justifyContent="space-between" direction="row">
          <Stack spacing={2} alignItems="center" direction="row">
            <Badge variant="dot">
              <span style={{ width: 10, height: 10, borderRadius: '50%', display: 'inline-block', backgroundColor: '#243E36' }} />
            </Badge>  
            <Typography variant="body2">Company Account Registration</Typography>
          </Stack>
          <Typography variant="body1">{companyCount}</Typography>
        </Stack>
        <Stack spacing={32.5} justifyContent="space-between"  direction="row">
          <Stack spacing={2} alignItems="center" direction="row">
            <Badge variant="dot">
              <span style={{ width: 10, height: 10, borderRadius: '50%', display: 'inline-block', backgroundColor: '#7CA982' }} />
            </Badge>  
            <Typography variant="body2">Public Account Registration</Typography>
          </Stack> 
          <Typography variant="body1">{publicCount}</Typography>
        </Stack>
        <Stack spacing={35} justifyContent="space-between"  direction="row">
          <Stack spacing={2} alignItems="center" justifyContent="flex-start"  direction="row">
              <Badge variant="dot">
                <span style={{ width: 10, height: 10, borderRadius: '50%', display: 'inline-block', backgroundColor: '#C2A83E' }} />
              </Badge>  
              <Typography variant="body2">eLicense Renewal</Typography>
          </Stack>
          <Typography variant="body1" >{renewalCount}</Typography>
        </Stack>
      </Stack>
    </Paper>
  );
};
