import { useNavigate } from "react-router-dom"
import Button from '@mui/material/Button';
import CancelIcon from '@mui/icons-material/Cancel';

import { DateTimeInput, Create, Toolbar, SaveButton, useTranslate, useNotify, useRefresh, useRedirect, SimpleForm, TextInput, required, minLength, maxLength, DateInput } from 'react-admin';

const BottomToolbar = ({ basePath }: any, { ...props }) => {
    const navigate = useNavigate()

    return (
        <Toolbar {...props} >
            <SaveButton disabled={props.invalid || props.pristine} />
            <Button variant="contained" onClick={() => navigate('/activation-schedule')} startIcon={<CancelIcon />}>
                Cancel
            </Button>
        </Toolbar>
    )
}

const ActivationScheduleCreate = (props: any) => {
    const translate = useTranslate()
    const notify = useNotify()
    const refresh = useRefresh();
    const redirect = useRedirect();

    const onSuccess = ({ data }: any) => {
        notify(`Activation Schedule "${data.response_code}" successfully created`)
        redirect('show', props.basePath, data.id);
        refresh();
    };

    const onCreateFailure = (error: any) => {
        if (typeof error === 'string') {
            notify(error)
        } else if (error.message)
            notify(error.message)
        else
            notify('ra.notification.http_error')
        redirect('/activation-schedule');
        refresh();
    }

    const validateDescription = [required(translate('resources.activation_schedule.errors.description.required')),
    minLength(5, translate('resources.activation_schedule.errors.description.minLength')),
    maxLength(100, translate('resources.activation_schedule.errors.description.maxLength'))]

    return (
        <Create title={translate('resources.activation_schedule.form.create')} onSuccess={onSuccess} onFailure={onCreateFailure}
            {...props} >
            <SimpleForm toolbar={<BottomToolbar />} redirect="create">
                <br />
                <DateTimeInput
                    label={translate('resources.activation_schedule.fields.time')}
                    source="time"
                />
                <TextInput variant="outlined" source="description"
                    validate={validateDescription}
                    resettable={true}
                    label={translate('resources.activation_schedule.fields.description')}
                    helperText={translate('resources.activation_schedule.helperText.description')}
                    fullWidth />
            </SimpleForm>
        </Create>
    )
}

export default ActivationScheduleCreate