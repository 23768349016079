import { createTheme } from '@mui/material/styles';
import { ThemeProvider } from '@mui/material/styles';
import { useTranslate } from 'react-admin';
import PublicCompanyRenewal from './components/public-company-renewal';
import ConversationVolume from './components/conversation-volume';
import RenewalCategory from './components/renewal-category';
import { Stack, Box, Dialog, DialogTitle, DialogContent, DialogActions, Checkbox, FormControl, FormGroup, FormControlLabel, Grid, Button } from '@mui/material';
import { useState, useEffect }  from 'react';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

const theme = createTheme({
    typography: {
        fontFamily: 'Roboto',
        fontSize: 16,
      },
});

const AnalyticDashboard = () => {
  const translate = useTranslate()
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [selectedCharts, setSelectedCharts] = useState<string[]>([]);

  const openDialog = () => {
    setDialogOpen(true);
  };
  
  const closeDialog = () => {
    setDialogOpen(false);
  };

  const handleDownloadPDF = async () => {
    if (selectedCharts.length === 0) {
      openDialog();
      return;
    }
  
    const chartsPerPage = 4; // Maximum number of charts per page
    let currentPage = 1;
  
    // Create a new jsPDF instance
    const pdf = new jsPDF();
  
    for (let index = 0; index < selectedCharts.length; index++) {
      const chartId = selectedCharts[index];
      // Get the chart element to capture
      const chartElement = document.getElementById(chartId);
  
      if (chartElement) {
        // Use html2canvas to capture the chart as an image
        const canvas = await html2canvas(chartElement, { allowTaint: true, backgroundColor: 'white', removeContainer: true });
  
        // Calculate the width and height for the PDF document
        const pdfWidth = 210;
        const pdfHeight = (canvas.height * pdfWidth) / canvas.width;
  
        if (index % chartsPerPage === 0) {
          // If it's the first chart on a new page, add a new page
          if (index !== 0) {
            pdf.addPage();
            currentPage++;
          }
        }
  		  
        // Calculate the position for the chart on the page
        const chartIndexOnPage = index % chartsPerPage;
        const columnCount = 2; // Number of columns per page
        const row = Math.floor(chartIndexOnPage / columnCount);
        const column = chartIndexOnPage % columnCount;
        const pageX = (pdfWidth / 2) * column;
        const pageY = (pdfHeight / 2) * row;
  
        // Add the captured chart image to the PDF document
        pdf.addImage(
          canvas.toDataURL('image/png'),
          'PNG',
          pageX,
          pageY,
          pdfWidth / 2,
          pdfHeight / 2
        );
  
        if (index === selectedCharts.length - 1) {
          // If it's the last chart, download the PDF file
          pdf.save('charts.pdf');
        }
      } else {
        console.error(`Element with ID '${chartId}' not found.`);
      }
    }
  };
  
  const handleChartSelection = (chartId: string) => {
    if (selectedCharts.includes(chartId)) {
      setSelectedCharts(selectedCharts.filter((id) => id !== chartId));
    } else {
      setSelectedCharts([...selectedCharts, chartId]);
    }
  };
  
  return (
    <ThemeProvider theme={theme}>
      <br/>
      <Box display="flex" justifyContent="flex-end">
        <Button
          variant="contained"
          color="primary"
          onClick={openDialog}
          sx={{ fontSize: '14px', width: '20%', padding: '8px 16px', margin: '8px' }}
        >
          Download Charts
        </Button>
      </Box>
      <Dialog open={isDialogOpen} onClose={closeDialog}>
        <DialogTitle>Select Charts</DialogTitle>
        <DialogContent>
          <FormControl component="fieldset">
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={selectedCharts.includes("registration-renewal")}
                    onChange={() => handleChartSelection("registration-renewal")}
                  />
                }
                label="Registration & Renewals"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={selectedCharts.includes("elicense-renewal-category")}
                    onChange={() => handleChartSelection("elicense-renewal-category")}
                  />
                }
                label="Elicense Renewal Category"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={selectedCharts.includes("conversation-volume")}
                    onChange={() => handleChartSelection("conversation-volume")}
                  />
                }
                label="Conversation Volumes"
              />
            </FormGroup>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDownloadPDF} color="primary">
            Download
          </Button>
        </DialogActions>
      </Dialog>

      <Grid container spacing={1}>
        <Grid item xs={4}  id="registration-renewal">
          <PublicCompanyRenewal/>
        </Grid>
        <br/>
        <Grid item xs={4} id="elicense-renewal-category">
          <RenewalCategory/>
        </Grid>
        <Grid item xs={4} id="conversation-volume" >
          <ConversationVolume/>
        </Grid>
      </Grid>
      <br/>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          {/* <ProgramCompositionChart/> */}
        </Grid>
      </Grid>
      <br/>

    </ThemeProvider>
  );
};

export default AnalyticDashboard;
