import {
    FormControl,
    IconButton,
    Input,
    InputAdornment,
    InputLabel,
    MenuItem
} from '@mui/material';
import {
    useContext,
    useEffect,
    useState
} from 'react';

import Select, { SelectChangeEvent } from '@mui/material/Select';
import ConversationContext from '../context/ConversationContext';
import BasicPopover from './components/filter-popper';

import ManageSearchOutlinedIcon from '@mui/icons-material/ManageSearchOutlined';
import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import { BACKEND_URL } from '../constants';


const ConversationToolbar = () => {
    const { filterValue, setFilterValue, filterBy, setFilterBy, botChannel, setBotChannel, filterTag, setFilterTag, filterID, setFilterID, filter, setFilter, selectedSenderId }: any = useContext(ConversationContext)
    const [remarks, setRemarks] = useState([]);
    const [remarkTag, setRemarkTag] = useState('');

    useEffect(() => {
        const fetchTagsChoices = async () => {
          try {
            const response = await fetch(`${BACKEND_URL}/fetch-tags-choices/60126023473`);
            const data = await response.json();
            setRemarks(data.tags);
          } catch (error) {
            console.error("Failed to fetch note from backend:", error);
          }
        };
        fetchTagsChoices();
      }, [selectedSenderId]);

    const handleTagChange = (event: SelectChangeEvent) => {
        setFilterTag(event.target.value);
        setFilterValue(event.target.value);
    };

    const handleRemarkChange = (event: SelectChangeEvent) => {
        setRemarkTag(event.target.value);
    };

    const handleIdChange = (event: any) => {
        setFilterID(event.target.value);
        setFilterValue(event.target.value);
    };

    const handleChange = (event: SelectChangeEvent) => {
        setFilterBy(event.target.value);
        if (event.target.value === 'null') {
            setBotChannel('')
            setFilterTag('')
            setFilterID('')
            setFilterValue('');
        }
    };

    const handleBotChannel = (
        event: React.MouseEvent<HTMLElement>,
        botChannel: string | null,
    ) => {
        setBotChannel(botChannel);
        setFilterValue(botChannel);
    };

    return (
        <div style={{ backgroundColor: '#f0f2f5', height: '8%', display: 'flex', justifyContent: 'flex-end', alignItems: 'center', overflow: 'auto' }}>
                <BasicPopover/>
        </div>
    )
}

export default ConversationToolbar

