import { useState, useEffect, useContext } from 'react'
import { usePermissions } from 'react-admin';
import { Divider, Paper } from '@mui/material';

import ConversationToolbar from './conversation-toolbar'
import ConversationList from './conversation-list'
import ConversationContainer from './conversation-container';
import ConversationChannel from './conversation-channel';

import { APP_PERMISSIONS } from '../constants';
import { HistoryProvider } from '../context/HistoryContext'
import HistoryContext from '../context/HistoryContext';

const ConversationShell = () => {
    // const classes = useStyles()
    const { selectedSenderId, chats }: any = useContext(HistoryContext)
    const [selectedConversation, setSelectedConversation] = useState(false)
    const {permissions} = usePermissions();

    useEffect(() => {
        // Avoid useEffect to run twice on mount at the first time
        if (selectedSenderId){
            setSelectedConversation(true)
        }
    }, [selectedSenderId]);


    return (
        <div style={{ height: '95vh', width: '100%', overflow: 'auto' }}>
            {
                permissions ? permissions.includes(APP_PERMISSIONS.CONVERSATION.READ) && 
                <>
                <HistoryProvider>
                    <br></br>
                    <Paper style={{ height: '95%', maxWidth: 'fit-content', display: 'inline-block', verticalAlign: 'middle' }}>
                        <ConversationToolbar />
                        <Divider/>
                        <ConversationList
                        />
                    </Paper>
                    <ConversationContainer/>
                </HistoryProvider>
                </>
                : ""
            }
        </div>
    )
}

export default ConversationShell