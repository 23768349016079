import React from "react";
import { BACKEND_URL } from '../../constants'
import { useState, useEffect }  from 'react';
import { List } from "lodash";
import { Stack, Paper, FormControl, InputLabel, Select, MenuItem, Typography, Divider, Box } from '@mui/material';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie, Doughnut } from 'react-chartjs-2';
import dayjs, { Dayjs } from 'dayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

export default function RenewalCategory() {
  const [chartData, setChartData] = useState([
    {
        "type": "E-License Renewal Category",
        "data": [
            {
              "category": "Beverage",
              "count": 0
            },
            {
              "category": "Clinic",
              "count": 0
            },
            {
              "category": "Food",
              "count": 0
            },
        ]
    }
  ]);
  const [basis, setBasis] = useState('daily');
  const [selectedStartDate, setSelectedStartDate] = useState(dayjs().subtract(1, 'day'));
  const [selectedEndDate, setSelectedEndDate] = useState(dayjs());

  const handleChangeBasis = (event: any) => {
    setBasis(event.target.value);
  };

  // Function to disable end dates earlier than the selected start date
  const shouldDisableDate = (day: any) => {
    // Convert selectedStartDate and day to Date objects
    const startDate = selectedStartDate ? new Date(selectedStartDate.toDate()) : null;
    const endDate = new Date(day);

    // If startDate is not set, don't disable any dates
    if (!startDate) {
        return false;
    }

    return endDate < startDate;
  };

  function generateColorList(dataLength: number, colorTone: number) {
    var colorList = [];
    var hue = colorTone; // set the hue to the desired color tone
  
    // loop through the data length and generate a color for each index
    for (var i = 0; i < dataLength; i++) {
      var saturation = 50 + Math.random() * 50; // set the saturation to a random value between 50 and 100
      var lightness = 50 + Math.random() * 25; // set the lightness to a random value between 50 and 75
  
      // create the color using HSL notation
      var color = "hsl(" + hue + ", " + saturation + "%, " + lightness + "%)";
  
      // add the color to the color list
      colorList.push(color);
  
      // increment the hue slightly for each index to create a gradient effect
      hue += 10;
    }
    return colorList;
  }

  const fetchCountFromBackend = async () => {
    const apiUrl = `${BACKEND_URL}/get-renewal-category?start_date=${selectedStartDate.format('YYYY-MM-DD')}&end_date=${selectedEndDate.format('YYYY-MM-DD')}`;
    fetch(apiUrl)
      .then((response) => response.json())
      .then((data) => {
        const newData = data;
        console.log(`${basis} renewal category data: `, newData);
        setChartData(newData);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {    
    fetchCountFromBackend();
  }, [selectedStartDate, selectedEndDate]);

  const options = {
    cutout: 70, // set the cutoutPercentage to 70 to create a custom inner radius
  };

  return (
    <Paper sx={{backgroundColor: 'white', boarderRadius: '12px', height: '100%'}}>
      <Box sx={{ m: 1, display: 'flex', justifyContent: 'space-between'}}>
      <Stack alignItems='center' direction="column">
          <Typography sx={{ m: 1, fontFamily: 'Roboto', fontWeight: 'bold' }} variant="body2">Elicense Renewal Category</Typography>
          <Stack spacing={2} direction="row" justifyContent="space-between" sx={{width: '90%'}}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={['DatePicker', 'DatePicker']}>
                  <DatePicker
                      sx= {{ 
                        '& .MuiInputBase-input': {
                          backgroundColor: 'white',
                          padding: '10px'
                        },
                      }}
                      label="From"
                      value={selectedStartDate}
                      onChange={(date: any) => setSelectedStartDate(date)}
                      disableFuture
                  />
              </DemoContainer>
            </LocalizationProvider>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={['DatePicker', 'DatePicker']}>
                  <DatePicker
                      sx= {{ 
                          '& .MuiInputBase-input': {
                            backgroundColor: 'white',
                            padding: '10px'
                          },
                      }}
                      label="To"
                      value={selectedEndDate}
                      onChange={(date: any) => setSelectedEndDate(date)}
                      disableFuture
                      shouldDisableDate={(day: any) => shouldDisableDate(day) === true && day !== selectedStartDate}
                  />
              </DemoContainer>
            </LocalizationProvider>
          </Stack>
        </Stack>
      </Box>
      <br/>
      <br/>
      <Stack flexDirection="row" alignItems="flex-end" justifyContent="space-around">
        {chartData.map((renewalData) => (
            <Box>
              <Box width={220} height={220}>
                <Doughnut 
                  options={options}
                  data={{
                  labels: renewalData?.data.map((item) => item.category),
                  datasets: [
                    {
                      data: renewalData?.data.map((item) => item.count),
                      backgroundColor: generateColorList(renewalData?.data.length, 200),
                      borderWidth: 0.0,
                    }
                  ]
                }}/>
              </Box>
              <br/>
              <br/>
              <br/>
              <Typography align='center' variant="body2">{renewalData.type}</Typography>
            </Box>
          ))}
      </Stack>
      <br/>
      <br/>
    </Paper>
  );
};