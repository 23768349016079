import {
    FunctionField,
    ShowButton,
    EditButton,
    Datagrid,
    FilterButton,
    List,
    Pagination,
    TextField,
    TextInput,
    TopToolbar,
    useListContext,
    usePermissions,
    useTranslate,
    Button,
} from 'react-admin';

import { BACKEND_URL } from '../constants';

const PredictionFilter = [
    <TextInput source="text" />,
    <TextInput source="predicted_intent" />,
    <TextInput source="confidence" />,
]

const downloadReport = async () => {
    const apiUrl = `${BACKEND_URL}/prediction/download`;
    fetch(apiUrl)
        .then((response) => response.blob())
        .then((blob) => {
            const url = window.URL.createObjectURL(new Blob([blob]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'prediction-report.csv');
            document.body.appendChild(link);
            link.click();
            link.parentNode?.removeChild(link);
        }
        )
}

// Paginator for the Prediction listing
const PredictionPaginator = (props: any) => <Pagination rowsPerPageOptions={[15, 30, 60, 90, 120, 150]} {...props} />


const PredictionList = ({ ...props }) => {
    const translate = useTranslate()
    const { permissions } = usePermissions();
    const listContext = useListContext();
    const {
        data,
        filterValues,
        setFilters,
        displayedFilters,
        isLoading,
    } = listContext;

    const ListActions = ({ permissions }: { permissions: any }) => (
        <TopToolbar>
            <Button label="Download" onClick={downloadReport} />
            <FilterButton />
        </TopToolbar>
    )

    const predictionStyle = (record: any, index: number) => ({
        backgroundColor: record.correct_intent == ''  ? '#fff': '#e8faed',
    })

    return (
        <List actions={<ListActions permissions={permissions} />} filters={PredictionFilter} title={translate('resources.prediction.form.list')} exporter={false} pagination={<PredictionPaginator />} perPage={15}>
            <Datagrid rowStyle={predictionStyle}>
                <TextField label={translate('resources.prediction.fields.created_at')} source="created_at"/>
                <TextField label={translate('resources.prediction.fields.text')} source="text" />
                <TextField label={translate('resources.prediction.fields.predicted_intent')} source="predicted_intent"/>
                <TextField label={translate('resources.prediction.fields.confidence')} source="confidence" />
                <TextField label={translate('resources.prediction.fields.correct_intent')} source="correct_intent" />
                <TextField label={translate('resources.prediction.fields.language')} source="language" />
                <EditButton />
                <ShowButton />
            </Datagrid>
        </List>
    )
}

export default PredictionList;

