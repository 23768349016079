import {
    useState,
    cloneElement,
    useEffect,
    Fragment,
    createContext,
    useContext,
    useReducer,
    useMemo,
    useRef
} from 'react'
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ReactLoading from "react-loading";
import { BACKEND_URL } from '../constants';

import { Box, IconButton, Input, Divider, Avatar, Paper, Typography, ToggleButton } from '@mui/material';
import HistoryContext from '../context/HistoryContext';
import BadgeAvatars from './components/badge-avatar';

const ConversationItem = ({ key, handleConversationClick, conversationIndex, sender_id, created_at, conversation_tag, bot_channel, user_message_count, room_id, agent_id, clock_color, session_status,time_epoch, university_id, sender_name }: any) => {
    const { messages, selectedSenderId }: any = useContext(HistoryContext)

    return (
        <ListItem style={{backgroundColor: selectedSenderId === sender_id ? '#efdfbb' : 'transparent'}}alignItems="center" button onClick={(event) => {handleConversationClick(event, conversationIndex, sender_id, bot_channel, room_id, agent_id, conversation_tag, created_at, session_status, time_epoch, university_id);}}>
            <ListItemAvatar>
                <BadgeAvatars color={clock_color}/>
            </ListItemAvatar>
            <ListItemText
                primary = {<><div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', lineHeight: '0.1' }}>
                <p style={{ minWidth: '20vh'}}>{sender_name?sender_name:sender_id}</p>
                </div></>}
                secondary={<><div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', lineHeight: '0.1', flexWrap: 'wrap'}}>
                    <p style={{ width: 'fit-content', wordWrap: 'break-word' }}>{created_at}</p>
                </div></>}
            />
        </ListItem>

    )
}

const ConversationList = () => {
    const { chats, setSelectedConversationTag, setSelectedSenderId, setSelectedRoomId, setSelectedRBotChannel,setSelectedAgentId, setSelectedCreatedAt, setSelectedSessionStatus, setSelectedUniversityId, filterName, filter}: any = useContext(HistoryContext)

    const [loading, setLoading]: any = useState(true)


    useEffect(() => {
        if (chats) {
            setLoading(false)
        } else {
            setLoading(true)
        }
    }, [chats]);

    function getHeight() {
        const height = 86;
        
        if (filter === true && filterName.length > 0){
            let initialValue = 86; // Initialize the variable to the initial value
            for (let i = 0; i < filterName.length; i++) {
                initialValue -= 7; // Subtract the reduction constant from the result for each element in the array
            }
            return initialValue.toString() + "%"; // Return the final result
        } else {
            return height.toString() + "%";
        }
    }

    const handleConversationClick = (event: any, conversationIndex: any, sender_id: any, bot_channel: any, room_id: any, agent_id: any, conversation_tag: any, created_at: any, session_status: any, time_epoch: any, university_id: any) => {
        setSelectedSenderId(sender_id)
        setSelectedRoomId(room_id)
        setSelectedRBotChannel(bot_channel)
        setSelectedAgentId(agent_id)
        setSelectedConversationTag(conversation_tag)
        setSelectedCreatedAt(time_epoch)
        setSelectedSessionStatus(session_status)
        setSelectedUniversityId(university_id)
    }

    return (
        <><div style={{ height: '90%', overflowX: 'hidden', overflowY: 'auto', display: 'inline-block', verticalAlign: 'middle', width: '35vh'}}>
            {
                loading == true &&
                <Box style={{ height: '100%', width: '100%', display: 'inline-flex', flexDirection: 'column', verticalAlign: 'middle', justifyContent: 'center', alignItems: 'center', overflow: 'hidden'}}>
                    <ReactLoading
                        type="spinningBubbles"
                        color="rgba(2, 189, 101, 1)"
                        height={100}
                        width={50}
                    />
                </Box>            
            }
            <Box>
                {chats && chats.length > 0 && chats.map((chat: any) => (
                    <List dense>
                        <ConversationItem
                            key={chat.sender_id}
                            created_at={chat.created_at}
                            conversation_tag={chat.conversation_tag}
                            sender_id={chat.sender_id}
                            handleConversationClick={handleConversationClick}
                            user_message_count={chat.user_message_count}
                            room_id={chat.room_id}
                            bot_channel={chat.bot_channel}
                            agent_id={chat.agent_id}
                            clock_color={chat.clock_color}
                            session_status={chat.session_status}
                            time_epoch={chat.time_epoch}
                            university_id={chat.university_id}
                            sender_name={chat.sender_name}
                        />
                        <Divider variant="inset" component="li" />
                    </List>
                    ))
                }
            </Box>
        </div>
        </>
    )
}

export default ConversationList