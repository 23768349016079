import { Box, IconButton, Input, Divider, Avatar, Paper, Typography, ToggleButton } from '@mui/material';
import {
    useState,
    useEffect,
    useContext
} from 'react'
import ConversationContext from '../../context/ConversationContext';
import Stack from '@mui/material/Stack';
import { createTheme } from '@mui/material/styles';
import { ThemeProvider } from '@mui/material/styles';
import { BACKEND_URL } from '../../constants'
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import FacebookOutlinedIcon from '@mui/icons-material/FacebookOutlined';
import ChatIcon from '@mui/icons-material/Chat';
import { SvgIcon } from '@mui/material';

const theme = createTheme({
    typography: {
      fontFamily: 'Roboto',
      fontSize: 16,
    },
});

export default function SenderDetails() {
    const { selectedCreatedAt, selectedSenderId, selectedSessionStatus, setSelectedSessionStatus, selectedBotChannel, selectedRoomId, senderName, setSenderName }: any = useContext(ConversationContext)
    
    useEffect(() => {
        setSenderName('')
        fetchSenderDetails();
    }, [selectedSenderId]);
  
    const fetchSenderDetails = async () => {
    try {
        const response = await fetch(`${BACKEND_URL}/contact-by-id/${selectedSenderId}`);
        const data = await response.json();
        setSenderName(data.name);
    } catch (error) {
        console.error("Failed to fetch sender from backend:", error);
        setSenderName('');
    }
    };

    function getCampusName() {
        if (selectedRoomId === '112508211735810' ){
            return "Kuala Lumpur Campus"
        } else if (selectedRoomId === '106366842382598' ){
            return "Kuching Campus"
        } else {
            return ""
        }
    }

    function getChannelIcon(botChannel: string) {
        if (botChannel === 'wa') {
            return <SvgIcon style={{ color: '25D366' }}><WhatsAppIcon/></SvgIcon>
        } else if (botChannel === 'fb') {
            return <SvgIcon style={{ color: '#1877F2' }}><FacebookOutlinedIcon/></SvgIcon>
        } else if (botChannel === 'webchat') {
            return <SvgIcon style={{ color: '#2E7D32' }}><ChatIcon/></SvgIcon>
        } 
    }

    return (
        <ThemeProvider theme={theme}>
            <Stack spacing={2} direction="row" >
                <SvgIcon style={{ color: '#CFD8DC', fontSize: 60 }}><AccountCircleIcon/></SvgIcon>
                <Stack spacing={1} direction="column" >
                    <Typography variant="caption">{senderName}</Typography>
                    <Stack alignContent='center' spacing={1} direction="row" >
                        {getChannelIcon(selectedBotChannel)} 
                        <Typography variant="caption">{selectedSenderId}</Typography>
                    </Stack>
                    <Typography variant="caption">{getCampusName()}</Typography>
                </Stack>
            </Stack>
        </ThemeProvider>
    );
}