import BasicPopover from './components/filter-popper';


const ConversationToolbar = () => {
    return (
        <div style={{ backgroundColor: '#f0f2f5', height: '8%', display: 'flex', justifyContent: 'flex-end', alignItems: 'center', overflow: 'auto' }}>
                <BasicPopover/>
        </div>
    )
}

export default ConversationToolbar

