import {
    Fragment,
    useContext,
} from 'react'
import {
    Typography,
    Stack,
    IconButton
} from '@mui/material';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import CallIcon from '@mui/icons-material/Call';
import CallEndIcon from '@mui/icons-material/CallEnd';
import ConversationContext from '../context/ConversationContext';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import FacebookIcon from '@mui/icons-material/Facebook';
import ChatIcon from '@mui/icons-material/Chat';
import { SvgIcon } from '@mui/material';

const ConversationHeader = () => {
    const { selectedAgentId,selectedSenderId, agentId, wsRef, inputDisabled, selectedRoomId, selectedBotChannel }: any = useContext(ConversationContext)
    const identity = sessionStorage.getItem('identity')
    const agent_id = sessionStorage.getItem('agent_id')
    const university_id = sessionStorage.getItem('university_id')?.toString()

    const handleCallIconClick = () => {
        wsRef.current.send(JSON.stringify({action: 'intercept', target_id: selectedSenderId, source_id: identity, room_id: selectedRoomId, bot_channel: selectedBotChannel, agent_id: agent_id}))
    }

    const handleCallEndIconClick = () => {
        wsRef.current.send(JSON.stringify({action: 'end_intercept', target_id: selectedSenderId, source_id: identity, room_id: selectedRoomId, bot_channel: selectedBotChannel, agent_id: agent_id}))
    }

    function getCampusName() {
        if (selectedRoomId === '112508211735810' ){
            return "Kuala Lumpur Campus"
        } else if (selectedRoomId === '106366842382598' ){
            return "Kuching Campus"
        } 
    }

    return (
        <div style={{ backgroundColor: '#f0f2f5', height: '8%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <List dense>
                <ListItem>
                    <ListItemText
                        primary={
                            <Fragment>
                                <Typography>
                                    {selectedSenderId}
                                </Typography>
                                <Stack spacing={1} direction="row">
                                {
                                    selectedBotChannel === 'wa' && <SvgIcon style={{ backgroundColor: '#25D366', color: 'white' }}><WhatsAppIcon/></SvgIcon>
                                }
                                {
                                    selectedBotChannel === 'fb' && <SvgIcon style={{ color: '#1877F2' }}><FacebookIcon/></SvgIcon>
                                }
                                {
                                    selectedBotChannel === 'webchat' && <SvgIcon style={{ color: '#2E7D32' }}><ChatIcon/></SvgIcon>
                                }
                                {
                                    selectedBotChannel === 'fb' &&                                <Typography>
                                        {getCampusName()}
                                    </Typography>
                                }
                                </Stack>
                            </Fragment>
                        }
                    />
                </ListItem>
            </List>
            { inputDisabled === false &&
                <IconButton disabled={(selectedAgentId !== agent_id) && (agent_id !== university_id)} onClick={(event) => handleCallEndIconClick()}><CallEndIcon color="error" fontSize='large' /></IconButton>
            }
        </div>
    )
}

export default ConversationHeader


            // { selectedAgentId && inputDisabled === true &&
            //     <IconButton disabled={(selectedAgentId !== agent_id) && (agent_id !== university_id)} onClick={(event) => handleCallIconClick()}><CallIcon color="success" fontSize='large' /></IconButton>
            // }
            // { !selectedAgentId && inputDisabled === true &&
            //     <IconButton onClick={(event) => handleCallIconClick()}><CallIcon color="success" fontSize='large' /></IconButton>
            // }