import { TranslationMessages } from 'ra-core';

export const englishMessages: TranslationMessages = {
    app: {
        configuration: 'Configuration',
        userAccount: 'User Account',
        language: 'Language',
        theme: {
            name: 'Theme',
            dark: 'Dark',
            light: 'Light',
        },
        menu: {
            security: 'Access Control',
            training: 'Training',
            conversation: 'Conversations',
            contacts: 'Contacts',
            promotion: 'Promotions',
            internalConversation: 'Internal',
            externalConversation: 'External',
            conversationHistory: 'History',
            audit_trail: ' Audit Trail',
        }
    },
    resources: {
        common: {
            fields: {
                created_by: 'Created By',
                updated_by: 'Updated By',
                created_at: 'Created At',
                updated_at: 'Updated At',
            }
        },
        user: {
            name: 'User Manager |||| Users',
            form: {
                create: 'Create User',
                edit: 'Edit User',
                show: 'View User',
                list: 'Users'
            },
            fields: {
                username: 'Username',
                fullname: 'Fullname',
                email: 'Email',
                enabled: 'Active',
                role: 'Role',
                password_expire_at: 'Password Expire At',
                password: 'Password',
                confirm_password: 'Confirm Password',
            },
            field_groups: {
                identity: 'User Identity',
                security_access: 'Security Access',
            },
            errors: {
                username: {
                    required: "Username is required",
                    minLength: "Username must be minimum %{min} characters",
                    maxLength: "Username must not more than %{max} characters",
                    regex: "Username must start with letters and optionally followed by numbers and @_. symbols",
                },
                fullname: {
                    required: 'Fullname is required',
                    minLength: "Fullname must be minimum %{min} characters",
                    maxLength: "Fullname must not more than %{max} characters",
                },
                email: {
                    invalid: 'Invalid email',
                    maxLength: "Email must not more than %{max} characters",
                },
                role: {
                    required: 'Role is required',
                },
                password: {
                    required: 'Password is required',
                    minLength: 'Password must be minimum %{mix} characters',
                    maxLength: 'Password must not more than %{max} characters',
                    notMatched: 'Password does not matched',
                    regex: "Password must consist of special symbol !@#$%&^()~, numbers, uppercase and lowercase letters",
                },
            },
            notification: {
                created: 'User created',
                updated: 'User updated'
            }
        },
        customer: {
            name: 'Customer |||| Customers',
        },
        domain: {
            name: 'Domain',

        },
        role: {
            name: 'Security Role |||| Roles',
            form: {
                create: 'Create Role',
                edit: 'Edit Role',
                show: 'View Role',
                list: 'Roles'
            },
            fields: {
                name: 'Name',
                enabled: 'Active',
                bot_channel: 'Channel',
                remark: 'Remark',
                text: 'Text'
            },
            helperText: {
                name: "Enter a role name",
                enabled: "Switch on to activate role"
            },
        },
        response: {
            name: 'Response |||| Responses',
            form: {
                create: 'Create Bot Responses',
                edit: 'Edit Bot Responses',
                show: 'View Bot Responses',
                list: 'Bot Responses'
            },
            fields: {
                response_code: 'Response',
                content: 'Content',
                bot_language: 'Language',
                bot_channel: 'Channel',
                remark: 'Remark',
                text: 'Text',
            },
            helperText: {
                response_code: "Enter the name of the response",
                bot_language: "Select language of the response",
                bot_channel: "Select the bot channel",
                remark: "Enter the name of the response",
                text: "Enter the text of the response",
                content: "Enter the content of the response"
            },
            errors: {
                bot_channel: {
                    required: "Bot channel is required",
                },
                bot_language: {
                    required: "Bot language is required",
                },
                response_code: {
                    required: "Response code is required",
                },
                text: {
                    required: "Text is required",
                },
            },
        },
        intent: {
            name: 'Intent |||| Intents',
            actions: {
                train: 'Train',
                done: 'Done',
            },
            form: {
                create: 'Create Intent',
                edit: 'Edit Intent',
                show: 'View Intent',
                list: 'Bot Intents'
            },
            fields: {
                name: 'Intent Name',
                phrases: 'Phrases',
                language: 'Language',
            },
            helperText: {
                name: "Enter the name of the intent",
                phrases: "Enter the phrases of the intent",
                language: "Select the language of the intent",
            },
            errors: {
                name: {
                    required: "Intent name is required",
                },
                language: {
                    required: "Language is required",
                },
                phrases: {
                    required: "Phrases is required",
                },
            },
        },
        activation_schedule: {
            name: 'Scedule |||| Schedules',
            form: {
                create: 'Create Activation Schedule',
                edit: 'Edit Activation Schedule',
                show: 'View Activation Schedule',
                list: 'Activation Schedules'
            },
            fields: {
                time: 'Date & time of Activation',
                description: 'Description',
            },
            helperText: {
                time: 'Enter the time of activation',
                description: 'Enter the description of the activation schedule',
            },
            errors: {
                description: {
                    minLength: 'Please provide longer description',
                    required: 'Description is required',
                }
            },
        },
        audit_trail: {
            name: 'Audit Trail |||| Audit Trails',
            fields: {
                created_at: 'Created At',
                user: 'User',
                action: 'Action',
                category: 'Category',
                description: 'Description',
            },
            form: {
                list: 'Audit Trails',
                show: 'View Audit Trails',
            },
        },
        prediction: {
            name: 'Prediction |||| Predictions',
            fields: {
                created_at: 'Creation Time',
                text: 'Text',
                language: 'Language',
                predicted_intent: 'Predicted Intent',
                confidence: 'Confidence',
                correct_intent: 'Correct Intent',
            },
            form: {
                list: 'Predictions',
                edit: 'Edit Prediction',
                show: 'View Prediction',
            },
            helperText: {
                text: 'User message',
                language: 'Language of the user message',
                correct_intent: 'Choose the correct intent for the user message',
                predicted_intent: 'Predicted intent for the user message',
            },
            errors: {
                correct_intent: {
                    required: 'Correct intent is required',
                },
                text: {
                    required: 'Text is required',
                },
            },
        },
        model: {
            name: 'Model |||| Models',
            actions: {
                train: {
                    en: 'Train EN',
                    bm: 'Train BM',
                    title: 'Train',
                    started: 'Training started',
                    in_progress: 'Training...',
                    error: {
                        in_progress: 'Unable to initiate the training process as a previous training session is currently in progress. Please wait until it is completed before attempting to start a new training session. ',
                    },
                },
                activate: {
                    title: 'Activate',
                    in_progress: 'Activating...',
                    success: 'Model activated',
                    started: 'Activating latest trained model...',
                }

            },
            form: {
                list: 'Models'
            },
            fields: {
                name: "Name",
                state: "State",
                language: "Language",
                date: "Date",
            },
            helperText: {
                response_code: "Enter the name of the response",
                bot_language: "Select language of the response",
                bot_channel: "Select the bot channel",
                remark: "Enter the name of the response",
                text: "Enter the text of the response",
                content: "Enter the content of the response"
            },
        },
        stories: {
            name: 'Story |||| Stories',
            form: {
                create: 'Create Bot Stories',
                edit: 'Edit Bot Stories',
                show: 'View Bot Stories',
                list: 'Bot Stories'
            },
            fields: {
                name: 'Title',
                type: 'Type',
                steps: 'Steps',
                language: 'Language',
                enabled: 'Active',
            },
            helperText: {
                name: 'Enter story name',
                type: 'Select story type',
                steps: 'Enter story steps',
                language: 'Select story language',
                enabled: 'Select to activate/deactivate story',
            },
            errors: {
                story_name: {
                    required: "Story Name is required",
                },
                type: {
                    required: 'Story Type is required',
                },
            },
        },
        rules: {
            name: 'Rule |||| Rules',
            form: {
                create: 'Create Bot Rules',
                edit: 'Edit Bot Rules',
                show: 'View Bot Rules',
                list: 'Bot Rules'
            },
        },
        promotions: {
            name: 'Promotion |||| Promotions',
            form: {
                create: 'Create Promotion',
                edit: 'Edit Promotion',
                show: 'View Promotion',
                list: 'Promotion'
            },
            fields: {
                target_name: 'Target Name',
                condition: 'Condition',
                title: 'Title',
                desc: 'Body Description',
                attachment: 'Attachment',
                broadcast_date: 'Broadcast Date',
                order: 'Telegram Order',
                file_upload: 'Browse or Drop Customer List here',
                customer_list: 'Customer List',
                template_name: 'Template Name',
                product_name: 'Product Name',
                product_details: 'Product Details',
                broadcast_name: 'Broadcast Name',
                customer_template: 'Customer List Template'

            },
            helperText: {
                target_name: "Enter the target's name",
                condition: "Type the condition",
                title: "Enter the campaign's title",
                desc: 'Enter a Description',
                order: "Switch on to allow order to be placed",
                template_name: "Choose a template",
                broadcast_name: 'Enter a Broadcast Name'

            },
        },
        contacts: {
            name: 'Contact |||| Contacts',
            form: {
                create: 'Create Contact',
                edit: 'Edit Contact',
                show: 'View Contact',
                list: 'Contacts'
            },
            fields: {
                salutation: 'Salutation',
                name: 'Fullname',
                nric: 'NRIC',
                contact_number: 'Contact number',
                email: 'Email',
                gender: 'Gender',
                marital: 'Marital Status',
                dob: 'Date of Birth',
                address: 'House Address',
                remark: 'Remark'
            },
            helperText: {
                salutation: 'Enter Customer\'s Salutation',
                name: 'Enter Customer\'s Fullname',
                nric: 'Enter Customer\'s NRIC',
                contact_number: 'Enter Customer\'s Contact number',
                email: 'Enter Customer\'s Email',
                gender: 'Enter Customer\'s Gender',
                marital: 'Enter Customer\'s Marital Status',
                dob: 'Enter Customer\'s Date of Birth',
                address: 'Enter Customer\'s House Address',
                remark: 'Enter Customer\'s Remark'
            },
        },
    },
    ra: {
        action: {
            add_filter: 'Add filter',
            add: 'Add',
            back: 'Go Back',
            bulk_actions: '1 item selected |||| %{smart_count} items selected',
            cancel: 'Cancel',
            clear_input_value: 'Clear value',
            clone: 'Clone',
            confirm: 'Confirm',
            create: 'Create',
            create_item: 'Create %{item}',
            delete: 'Delete',
            edit: 'Edit',
            export: 'Export',
            list: 'List',
            refresh: 'Refresh',
            remove_filter: 'Remove this filter',
            remove: 'Remove',
            save: 'Save',
            search: 'Search',
            select_all: 'Select all',
            select_row: 'Select this row',
            show: 'Show',
            sort: 'Sort',
            undo: 'Undo',
            unselect: 'Unselect',
            expand: 'Expand',
            close: 'Close',
            open_menu: 'Open menu',
            close_menu: 'Close menu',
            update: 'Update',
            move_up: 'Move up',
            move_down: 'Move down',
            open: 'Open',
            toggle_theme: 'Toggle Theme',
        },
        boolean: {
            true: 'Yes',
            false: 'No',
            null: ' ',
        },
        page: {
            create: 'Create %{name}',
            dashboard: 'Dashboard',
            edit: '%{name} #%{id}',
            error: 'Something went wrong',
            list: '%{name}',
            loading: 'Loading',
            not_found: 'Not Found',
            show: '%{name} #%{id}',
            empty: 'No %{name} yet.',
            invite: 'Do you want to add one?',
        },
        input: {
            file: {
                upload_several:
                    'Drop some files to upload, or click to select one.',
                upload_single: 'Drop a file to upload, or click to select it.',
            },
            image: {
                upload_several:
                    'Drop some pictures to upload, or click to select one.',
                upload_single:
                    'Drop a picture to upload, or click to select it.',
            },
            references: {
                all_missing: 'Unable to find references data.',
                many_missing:
                    'At least one of the associated references no longer appears to be available.',
                single_missing:
                    'Associated reference no longer appears to be available.',
            },
            password: {
                toggle_visible: 'Hide password',
                toggle_hidden: 'Show password',
            },
        },
        message: {
            about: 'About',
            are_you_sure: 'Are you sure?',
            bulk_delete_content:
                'Are you sure you want to delete this %{name}? |||| Are you sure you want to delete these %{smart_count} items?',
            bulk_delete_title:
                'Delete %{name} |||| Delete %{smart_count} %{name}',
            bulk_update_content:
                'Are you sure you want to update this %{name}? |||| Are you sure you want to update these %{smart_count} items?',
            bulk_update_title:
                'Update %{name} |||| Update %{smart_count} %{name}',
            delete_content: 'Are you sure you want to delete this item?',
            delete_title: 'Delete %{name} #%{id}',
            details: 'Details',
            error:
                "A client error occurred and your request couldn't be completed.",
            invalid_form: 'The form is not valid. Please check for errors',
            loading: 'The page is loading, just a moment please',
            no: 'No',
            not_found:
                'Either you typed a wrong URL, or you followed a bad link.',
            yes: 'Yes',
            unsaved_changes:
                "Some of your changes weren't saved. Are you sure you want to ignore them?",
        },
        navigation: {
            no_results: 'No results found',
            no_more_results:
                'The page number %{page} is out of boundaries. Try the previous page.',
            page_out_of_boundaries: 'Page number %{page} out of boundaries',
            page_out_from_end: 'Cannot go after last page',
            page_out_from_begin: 'Cannot go before page 1',
            page_range_info: '%{offsetBegin}-%{offsetEnd} of %{total}',
            partial_page_range_info:
                '%{offsetBegin}-%{offsetEnd} of more than %{offsetEnd}',
            current_page: 'Page %{page}',
            page: 'Go to page %{page}',
            first: 'Go to first page',
            last: 'Go to last page',
            next: 'Go to next page',
            previous: 'Go to previous page',
            page_rows_per_page: 'Rows per page:',
            skip_nav: 'Skip to content',
        },
        sort: {
            sort_by: 'Sort by %{field} %{order}',
            ASC: 'ascending',
            DESC: 'descending',
        },
        auth: {
            auth_check_error: 'Please login to continue',
            user_menu: 'Profile',
            username: 'Username',
            password: 'Password',
            sign_in: 'Sign in',
            sign_in_error: 'Authentication failed, please retry',
            logout: 'Logout',
        },
        notification: {
            updated: 'Element updated |||| %{smart_count} elements updated',
            created: 'Element created',
            deleted: 'Element deleted |||| %{smart_count} elements deleted',
            bad_item: 'Incorrect element',
            item_doesnt_exist: 'Element does not exist',
            http_error: 'Server communication error',
            data_provider_error:
                'dataProvider error. Check the console for details.',
            i18n_error:
                'Cannot load the translations for the specified language',
            canceled: 'Action cancelled',
            logged_out: 'Your session has ended, please reconnect.',
            not_authorized: "You're not authorized to access this resource.",
        },
        validation: {
            required: 'Required',
            minLength: 'Must be %{min} characters at least',
            maxLength: 'Must be %{max} characters or less',
            minValue: 'Must be at least %{min}',
            maxValue: 'Must be %{max} or less',
            number: 'Must be a number',
            email: 'Must be a valid email',
            oneOf: 'Must be one of: %{options}',
            regex: 'Must match a specific format (regexp): %{pattern}',
        },
        saved_queries: {
            label: 'Saved queries',
            query_name: 'Query name',
            new_label: 'Save current query...',
            new_dialog_title: 'Save current query as',
            remove_label: 'Remove saved query',
            remove_label_with_name: 'Remove query "%{name}"',
            remove_dialog_title: 'Remove saved query?',
            remove_message:
                'Are you sure you want to remove that item from your list of saved queries?',
            help: 'Filter the list and save this query for later',
        },
    },
}
