import React, {cloneElement} from 'react'
import { useTranslate, Filter, TextInput, CreateButton,FilterButton, EditButton, ShowButton, useListContext, TopToolbar, sanitizeListRestProps, ExportButton,Button,List, Datagrid, TextField, Pagination, downloadCSV, BooleanField, useRedirect, usePermissions } from 'react-admin'
import AddIcon from '@mui/icons-material/Add'
import { APP_PERMISSIONS } from '../constants'

// const RoleFilter = (props: any) => {
//     const translate = useTranslate()
//     return (
//         <Filter {...props}>
//             <TextInput label={translate('resources.role.fields.name')} source="role_name"/>
//         </Filter>
//     )
// }

const RoleFilter = [
    <TextInput source="role_name"/>
]

const RolePaginator = (props: any) => <Pagination rowsPerPageOptions={[10,25,50]} {...props}/>

const ListActions = ({permissions}: {permissions: any}) => (
    <TopToolbar>
        <FilterButton/>
        <CreateButton/>
    </TopToolbar>
)

const RoleList = ({...props}) => {
    const { permissions } = usePermissions();
    const translate = useTranslate()
    return (
        <List actions={<ListActions permissions= {permissions} />} title={translate('resources.role.form.list')} bulkActionButtons={false} exporter={false} pagination={<RolePaginator />} filters={RoleFilter}>
            <Datagrid>
                <TextField label={translate('resources.role.fields.name')} source="role_name"/>
                <BooleanField label={translate('resources.role.fields.enabled')}source="enabled"/> 
                <ShowButton />
                {permissions ? permissions.includes("ROLE:WRITE") && <EditButton /> : ""}
            </Datagrid>
        </List>
    )
}

export default RoleList