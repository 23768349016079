import { useNavigate } from "react-router-dom"
import Button from '@mui/material/Button';
import CancelIcon from '@mui/icons-material/Cancel';
import { Edit, Toolbar, DeleteButton, SaveButton, useTranslate, TopToolbar, sanitizeListRestProps, DateTimeInput, SimpleForm, TextInput, required, minLength, maxLength } from 'react-admin';

const ListActions = (props: any) => {
    const {
        className,
        exporter,
        filters,
        maxResults,
        ...rest
    } = props

    return (
        <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
        </TopToolbar>

    )
}

const BottomToolbar = ({ ...props }) => {
    const navigate = useNavigate()

    return (
        <Toolbar {...props} >
            <SaveButton disabled={props.invalid || props.pristine} />
            <Button variant="contained" onClick={() => navigate(`/activation-schedule`)} startIcon={<CancelIcon />}>
                Cancel
            </Button>
            <DeleteButton />
        </Toolbar>
    )
}


const ActivationScheduleEdit = ({ ...props }) => {
    const translate = useTranslate()

    const validateDescription = [required(translate('resources.activation_schedule.errors.description.required')),
    minLength(5, translate('resources.activation_schedule.errors.description.minLength')),
    maxLength(100, translate('resources.activation_schedule.errors.description.maxLength'))]

    return (
        <Edit title={translate('resources.activation_schedule.form.create')}
            {...props} >
            <SimpleForm toolbar={<BottomToolbar />} redirect="create">
                <br />
                <DateTimeInput
                    label={translate('resources.activation_schedule.fields.time')}
                    source="time"
                />
                <TextInput variant="outlined" source="description"
                    validate={validateDescription}
                    resettable={true}
                    label={translate('resources.activation_schedule.fields.description')}
                    helperText={translate('resources.activation_schedule.helperText.description')}
                    fullWidth />
            </SimpleForm>
        </Edit>
    )
}

export default ActivationScheduleEdit