import { createContext, useState, useEffect, useRef, useReducer, useMemo } from "react";
//import axios from 'axios';
import { ActionReducer } from '../reducers/reducers';
import { WEBSOCKET_URL, BACKEND_URL } from '../constants';
import { combine2Validators } from "ra-core";
import axios from 'axios';
import dayjs, { Dayjs } from 'dayjs';


const HistoryContext = createContext({});

export const HistoryProvider = ({ children }: {children: any}) => {
    const [selectedSenderId, setSelectedSenderId] = useState('')
    const [selectedRoomId, setSelectedRoomId] = useState('')
    const [selectedAgentId, setSelectedAgentId] = useState(null)
    const [selectedBotChannel, setSelectedRBotChannel] = useState('')
    const [selectedConversationTag, setSelectedConversationTag]: any = useState(null)
    const [chats, setConversationsList] = useState(null)

    const [messages, setMessages] = useState<any[]>([]);
    const [agentMessage, setAgentMessage] = useState<any[]>([]);
    
    const [unreadMessages, setUnreadMessages]: any[] = useState([])
    const [targetId, setTargetId] = useState(null)
    const [inputDisabled, setInputDisabled] = useState(true)
    const listref = useRef(false)
    const [isPaused, setPause] = useState(false);
    const wsRef = useRef<null | WebSocket>(null); 
    const [wsConn, setWsConn] = useState<null | WebSocket>(null); 
    const [onIntercept, setOnIntercept] = useState(false)
    const [result, dispatch] = useState('')
    const [data, setData] = useReducer(ActionReducer, '')
    const [universityId, setUniversityId] = useState(null)
    const [agentId, setAgentId] = useState(null)
    const [agentInfo, setAgentInfo]: any = useState(null)
    const [loadAgent, setLoadAgent]: any = useState(false)
    const [pendingRequest, setPendingRequest]: any = useState(false)
    const [agentIsHandler, setAgentIsHandler]: any = useState(false)
    const [loadAgentPermission, setLoadAgentPermission]: any = useState(false)
    const [handoverAgentId, setHandoverAgentId]: any = useState(null)
    const [note, setNote] = useState("");
    const [transferButtonClicked, setTransferButtonClicked]: any = useState(false)
    const [selectedCreatedAt, setSelectedCreatedAt]: any = useState(null)
    const [selectedSessionStatus, setSelectedSessionStatus]: any = useState('Online')
    const [receiveHandover, setReceiveHandover]: any = useState(false)
    const [names, setNames] = useState<string[]>([]);
    const [tagName, setTagName] = useState<string[]>([]);
    const [botChannel, setBotChannel] = useState('all');
    const [filterBy, setFilterBy] = useState('');
    const [filterTag, setFilterTag] = useState('');
    const [filterID, setFilterID] = useState('');
    const [filter, setFilter] = useState(false);
    const [filterValue, setFilterValue] = useState('');
    const [filterChannel, setFilterChannel] = useState('');
    const [selectedUniversityId, setSelectedUniversityId] = useState(null);
    const [socketioConnection, setSocketioConnection] = useState(true);
    const [userConnection, setUserConnection] = useState(true);
    const [subFilter, setSubFilter] = useState('');
    const [subFilterValue, setSubFilterValue] = useState('');
    const [filterName, setFilterName] = useState<string[]>([]);
    const [senderName, setSenderName] = useState(null);
    const [branch, setBranch] = useState('');
    const [department, setDepartment] = useState('');
    const [company, setCompany] = useState('');
    const [id, setId] = useState('');
    const [text, setText] = useState('');
    const [status, setStatus] = useState('');
    const [tags, setTags] = useState('');
    const [useFilter, setUseFilter] = useState(false);
    const [requestChat, setRequestChat] = useState(false);
    const [checked, setChecked] = useState(false);
    const [isOnline, setIsOnline] = useState(true);
    const [network, setNetwork] = useState(true)
    const [selectedStartDate, setSelectedStartDate] = useState(dayjs());
    const [selectedEndDate, setSelectedEndDate] = useState(dayjs());

    const identity = sessionStorage.getItem('identity')
    const agent_id = sessionStorage.getItem('agent_id')
    const access_token = sessionStorage.getItem('token')

    const loadData = async () => {
        const response = await fetch(`${BACKEND_URL}/conversations`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${access_token}`
            }
        })
        const data = await response.json();
        setConversationsList(data.result);
    }

    const loadFilteredData = async () => {
        const url = new URL(`${BACKEND_URL}/conversations-by-filters`);
        if (branch !== '' ){
            url.searchParams.append('branch', branch);
        }
        if (department !== '' ){
            url.searchParams.append('department', department);
        }
        if (company !== '' ){
            url.searchParams.append('company', company);
        }
        if (id !== '' ){
            url.searchParams.append('id', id);
        }
        if (text !== '' ){
            url.searchParams.append('text', text);
        }
        if (status !== '' ){
            url.searchParams.append('status', status);
        }
        if (tags !== '' ){
            url.searchParams.append('tags', tags);
        }       
        if (checked !== false ){
            url.searchParams.append('agent', agent_id?agent_id:'');
        }
        if (selectedStartDate !== dayjs() ){
            url.searchParams.append('start_date', selectedStartDate.format('YYYY-MM-DD'));
            url.searchParams.append('end_date', selectedEndDate.format('YYYY-MM-DD'));
        }
        const headers = {
            'Content-Type': 'application/json',
            "Authorization": `Bearer ${access_token}`
        };
    
        const requestOptions = {
            method: 'GET',
            headers: headers,
        };

        return fetch(url, requestOptions)
          .then(response => response.json())
          .then(data => {
            setConversationsList(data.result);
          })
          .catch(error => {
            console.error(error);
            return error;
          });
    }

    useEffect(() => {
        loadData();
    }, []);

    useEffect(() => {
        if (useFilter === false) {
            loadData();
        }

        loadFilteredData();

        setConversationsList(null)
    }, [useFilter, filterChannel, selectedStartDate, selectedEndDate, branch, department, company, id, text, status, tags]);

    useEffect(() => {
        if (onIntercept === false){
            setInputDisabled(true)
        } else if (onIntercept === true){
            setInputDisabled(false)
        }
    }, [onIntercept]);

    useEffect(() => {
        // reinitialize all state on sender id change
        setAgentId(null)
        setUniversityId(null)
        setAgentInfo(null)
        setTransferButtonClicked(false)
        setAgentIsHandler(false)
        setNote("")
        setSocketioConnection(true)
        setUserConnection(true)
        setAgentMessage([])
    }, [selectedSenderId]);

    return (
        <HistoryContext.Provider value={{
            // contains all the shared states needed across the conversation module
            chats, selectedSenderId, setSelectedSenderId,
            selectedConversationTag, setSelectedConversationTag,
            selectedAgentId, setSelectedAgentId,
            messages, setMessages, targetId, inputDisabled,
            result, dispatch, wsRef,
            onIntercept, unreadMessages, network,
            selectedRoomId, setSelectedRoomId,
            selectedBotChannel, setSelectedRBotChannel,
            universityId, setUniversityId,
            agentId, setAgentId,
            agentInfo, setAgentInfo,
            transferButtonClicked, setTransferButtonClicked,
            pendingRequest, agentIsHandler, setAgentIsHandler,
            loadAgent, setLoadAgent, 
            loadAgentPermission, setLoadAgentPermission,
            selectedCreatedAt, setSelectedCreatedAt,
            handoverAgentId, setHandoverAgentId,
            selectedSessionStatus, setSelectedSessionStatus,
            receiveHandover, setReceiveHandover,
            note, setNote,
            names, setNames,
            tagName, setTagName,
            filterBy, setFilterBy, 
            botChannel, setBotChannel,
            filterTag, setFilterTag, 
            filterID, setFilterID, 
            filter, setFilter,
            filterValue, setFilterValue,
            filterChannel, setFilterChannel,
            selectedUniversityId, setSelectedUniversityId,
            socketioConnection, setSocketioConnection,
            userConnection, setUserConnection,
            subFilterValue, setSubFilterValue,
            subFilter, setSubFilter,
            filterName, setFilterName,
            senderName, setSenderName,
            branch, setBranch,
            department, setDepartment,
            company, setCompany,
            id, setId,
            text, setText,
            status, setStatus,
            tags, setTags,
            useFilter, setUseFilter,
            requestChat, setRequestChat,
            checked, setChecked,
            agentMessage, setAgentMessage,
            setTargetId,
            isOnline, setIsOnline,
            selectedStartDate, setSelectedStartDate,
            selectedEndDate, setSelectedEndDate
        }}>
            {children}
        </HistoryContext.Provider>
    )
}

export default HistoryContext;
